import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { RectangularLoader } from "../molecules/skeleton-loader/RectangularLoader";
import { ModalLoader } from "../organisms/skeleton-loaders/ModalLoader";

type SmallModalProps = {
  /** Whether the modal is open */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** If specified, the modal displays the title along with a close icon */
  title?: string;
  /** Whether the modal is open */
  children: ReactNode;
  /** Bigger modal */
  size?: "small" | "medium" | "large" | "xl";
  rightComponent?: ReactNode;
  customTitle?: ReactNode;
  isLoading?: boolean;
  maxHeight?: string;
  minHeight?: string;
  sx?: SxProps<Theme>;
  showCloseButton?: boolean;
  hideContentPadding?: boolean;
};

const widths = {
  small: "430px",
  medium: "693px",
  large: "1080px",
  xl: "80%",
  undefined: "430px",
};

export const SmallModal = (props: SmallModalProps) => {
  const {
    open,
    onClose,
    children,
    title,
    size = "small",
    rightComponent,
    customTitle,
    isLoading = false,
    maxHeight,
    minHeight,
    sx,
    hideContentPadding = false,
    showCloseButton = true,
  } = props;
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 6,
          width: widths[size],
          margin: 0,
          padding: hideContentPadding ? "0px" : "15px",
          background: theme.palette.background.paper,
          border: `1px solid ${theme.palette.custom.tableBorder}`,
          maxWidth: widths[size],
          maxHeight: maxHeight,
          minHeight: minHeight,
        },
      }}
      sx={{
        "& .MuiDialogTitle-root": { p: 0, m: 0 },
        "& .MuiDialogContent-root": { p: 0, m: 0 },
        "& .MuiLoadingButton-root": { height: "35px" },
        "& .MuiTypography-h2": { fontWeight: 600 },
        "& .MuiTypography-h3": { fontWeight: 600 },
        ...sx,
      }}
    >
      {title != null && (
        <DialogTitle
          padding={hideContentPadding ? "15px 15px 0 15px !important" : "0px"}
          marginBottom="10px !important"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            justifyItems="flex-start"
          >
            <Box display="flex" flexGrow={1} alignItems="center">
              {isLoading ? (
                <RectangularLoader width="100%" maxWidth="300px" height="26px" />
              ) : customTitle ? (
                customTitle
              ) : (
                <Typography variant="h2">{title}</Typography>
              )}
            </Box>
            <Box
              sx={{ lineHeight: 0 }}
              display="flex"
              alignItems="center"
              flexDirection="row"
              gap="5px"
            >
              {rightComponent}
              {showCloseButton && (
                <IconButton aria-label="close" onClick={onClose} sx={{ padding: 0 }}>
                  <NavbarIcon variant="dialog-close" sx={{ width: "auto", height: "auto" }} />
                </IconButton>
              )}
            </Box>
          </Box>
        </DialogTitle>
      )}
      {isLoading ? <ModalLoader showPadding={title === undefined} /> : children}
    </Dialog>
  );
};

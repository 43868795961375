import {
  Box,
  Divider,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Table as MuiTable,
} from "@mui/material";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type TableLoaderProps<T extends { id: string }> = {
  columnsVisibility: IColumnVisibility<T>[];
  rows: number;
};

export const TableLoader = <T extends { id: string }>(props: TableLoaderProps<T>) => {
  const { columnsVisibility, rows } = props;

  const theme = useTheme();
  const visibleColumns = columnsVisibility.filter((col) => col.visible);

  return (
    <>
      <TableContainer
        sx={{
          borderRadius: 0,
          boxShadow: "none",
          border: "none",
          display: "flex",
          flex: 1,
          height: "100%",
          "& .MuiTableRow-root": {
            borderTop: "1px solid",
            borderTopColor: theme.palette.custom.secondaryBorder,
          },
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <MuiTable sx={{ borderRadius: 0 }}>
          <TableHead
            sx={{
              borderRadius: 0,
              borderBottom: "1px solid",
              borderBottomColor: theme.palette.custom.secondaryBorder,
            }}
          >
            <TableRow>
              {visibleColumns.map((col, idx) => (
                <TableCell
                  key={col.field}
                  sx={{
                    height: "35px",
                    padding: 0,
                    maxWidth: col.columnMaxWidth,
                    width: col.columnMaxWidth,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {idx > 0 && (
                      <Divider sx={{ height: "14px", width: "2px" }} orientation="vertical" />
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        padding: "0 0 0 10px",
                        color: theme.palette.custom.withoutFillButton,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {col.headerName}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(rows)].map((_, idx) => (
              <TableRow
                key={idx}
                sx={{
                  height: "48px",
                }}
              >
                {columnsVisibility.map((col, idx) => {
                  const tableCellMinWidth = (minWidth: number = 80) => {
                    try {
                      if (visibleColumns[idx]?.columnMinWidth) {
                        return `${visibleColumns[idx]?.columnMinWidth}px`;
                      }
                      const maxSize = visibleColumns[idx]?.columnMaxWidth ?? minWidth;
                      return `${maxSize < minWidth ? maxSize : minWidth}px`;
                    } catch (ex) {
                      return `${minWidth}px`;
                    }
                  };
                  return (
                    <TableCell
                      key={col.field}
                      sx={{ padding: "11px", minWidth: tableCellMinWidth(), maxWidth: "300px" }}
                    >
                      <RectangularLoader width="100%" height="25px" />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
};

import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { riskRegistryListTableColumns } from "../../organisms/compliance/tables/RiskRegistryListTableColumns";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { ListViewLoader } from "./ListViewLoader";

export const RiskScenarioLoader = () => {
  const theme = useTheme();
  const columnsVisibility = riskRegistryListTableColumns({
    onUnlink: (risk) => {},
    vendor: false,
  });
  const vendorColumnsVisibility = riskRegistryListTableColumns({
    onUnlink: (risk) => {},
    vendor: true,
  });
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "0 0 15px 0",
      }}
    >
      <SecondaryBox>
        <DetailsLoader />
      </SecondaryBox>
      <ListViewLoader
        paddingBottom={false}
        columnsVisibility={columnsVisibility}
        tableSize="small"
      />
      <ListViewLoader
        paddingBottom={false}
        columnsVisibility={vendorColumnsVisibility}
        tableSize="small"
      />
    </Box>
  );
};

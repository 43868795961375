import {
  MLFlowModelResponse,
  MLFlowModelVersion,
  MLFlowModelVersionResponse,
  MLFlowModelVersionsFetchOptions,
  MLFlowRegisteredModelResponse,
} from "../models/types";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { mlflowGetAuthenticated } from "./ApiService";

const mlflowEndpoints = {
  models: () => "/mlflow-static/ajax-api/2.0/mlflow/registered-models",
  versions: () => `/mlflow-static/ajax-api/2.0/mlflow/model-versions`,
};

export const getMLFlowModels = (search: string) => {
  return mlflowGetAuthenticated<MLFlowModelResponse>(
    `${mlflowEndpoints.models()}/search?filter=name+ilike+%27%25${search}%25%27&max_results=25&order_by=name+ASC`
  );
};

export const getMLFlowModel = (model_name: string) => {
  return mlflowGetAuthenticated<MLFlowRegisteredModelResponse>(`${mlflowEndpoints.models()}/get`, {
    params: {
      name: model_name,
    },
  });
};

export const getMLFlowModelVersions = async ({
  model_name,
  max_results,
  page_token,
}: MLFlowModelVersionsFetchOptions): Promise<ListResponse<MLFlowModelVersion>> => {
  const { data } = await mlflowGetAuthenticated<MLFlowModelVersionResponse>(
    `${mlflowEndpoints.versions()}/search`,
    {
      params: {
        filter: `name='${model_name}'`,
        max_results: max_results ?? 25,
        page_token,
      },
    }
  );

  return {
    count: data?.model_versions ? data.model_versions.length : 0,
    next: null,
    previous: null,
    results: data?.model_versions ?? [],
  };
};

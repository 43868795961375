import {
  EvidenceTestInScopeResource,
  EvidenceTestInScopeResourceFetchOptions,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPostAuthenticated } from "./ApiService";

const evidenceEndpoints = {
  list: () => `/evidence_test_in_scope_resources`,
  retrieve: (resourceId: string) => `/evidence_test_in_scope_resources/${resourceId}`,
  evaluate: (resourceId: string) => `/evidence_test_in_scope_resources/${resourceId}/evaluate`,
  handle_deactive: (resourceId: string) =>
    `/evidence_test_in_scope_resources/${resourceId}/handle_deactive`,
};

export const getEvidenceTestInScopeResources = (
  params: EvidenceTestInScopeResourceFetchOptions
) => {
  return httpGetAuthenticated<ListResponse<EvidenceTestInScopeResource>>(evidenceEndpoints.list(), {
    params,
  });
};

export const getEvidenceTestInScopeResource = (resourceId: string) => {
  return httpGetAuthenticated<EvidenceTestInScopeResource>(evidenceEndpoints.retrieve(resourceId), {
    params: {
      fairo_data: true,
    },
  });
};

export const evaluateEvidenceTestInScopeResource = (resourceId: string) => {
  return httpGetAuthenticated(evidenceEndpoints.evaluate(resourceId));
};

export const deactivateEivdenceTestInScopeResource = async (
  resourceId: string,
  payload: {
    reason?: string;
    status: "DEACTIVATED" | "PENDING";
  }
) => {
  const response = await httpPostAuthenticated(
    evidenceEndpoints.handle_deactive(resourceId),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.EvidenceTestResource] });
  return response;
};

export const addEvidenceTestInScopeResource = async (payload: {
  evidence_test: string;
  resource_id: string;
  resource_type: string;
}) => {
  const response = await httpPostAuthenticated(evidenceEndpoints.list(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.EvidenceTestResource] });
  return response;
};

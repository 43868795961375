import { Button } from "@mui/material";
import UserTag from "../../components/UserTag";
import { useAsset } from "../../hooks/useAssets";
import { useMetricResults } from "../../hooks/useMetricResults";
import { MetricResult, TestResult } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { EmptyTable } from "../../molecules/EmptyTable";
import ParametersList from "../../molecules/ParametersList";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { useUsers } from "../../services/UserSettingsService";
import { defaultDateDisplay } from "../../utilities/UIHelper";
import { APITable } from "../APITable";
import { useTestResults } from "../../hooks/useTestResults";
import { StatusBadge } from "../../atoms/StatusBadge";

type MetricResultTestResultListCustomFieldProps = {
  model: string;
};

export const MetricResultTestResultListCustomField = ({
  model,
}: MetricResultTestResultListCustomFieldProps) => {
  const metricResultColumnsVisibility: IColumnVisibility<MetricResult>[] = [
    {
      field: "metric_result_num",
      headerName: "Result",
      visible: true,
      columnMaxWidth: 120,
      columnMinWidth: 120,
      renderer: (result) => (
        <TableTextWithEllipsis fontWeight={600} value={`Result-${result.metric_result_num}`} />
      ),
    },
    {
      field: "value",
      headerName: "Result Value",
      visible: true,
      columnMinWidth: 200,
      columnMaxWidth: 200,
      renderer: (result) => <TableTextWithEllipsis value={result?.value} />,
    },
    {
      field: "parameter_values",
      headerName: "Parameter Values",
      visible: true,
      columnMinWidth: 300,
      renderer: (result) => (
        <ParametersList
          parameters={
            result?.parameter_values?.map((parameter_value) => ({
              id: parameter_value.id,
              name: parameter_value?.parameter?.name,
              type: parameter_value?.field_type,
              parameterId: parameter_value?.parameter?.id,
              parameterValue: parameter_value?.value,
              parameterValueName: parameter_value?.value_name,
            })) ?? []
          }
          fetchUserDetails={useUsers}
          fetchAssetDetails={useAsset}
        />
      ),
    },
    {
      field: "assignee_name",
      headerName: "Assignee",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
      renderer: (result) =>
        result?.assignee_name ? <UserTag name={result?.assignee_name} /> : "-",
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMinWidth: 110,
      columnMaxWidth: 110,
      renderer: (result) => defaultDateDisplay(result.updated_on),
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
      renderer: (result) => <UserTag name={result.created_by.name} />,
    },
  ];

  const testResultColumnsVisibility: IColumnVisibility<TestResult>[] = [
    {
      field: "test_result_num",
      headerName: "Name",
      visible: true,
      columnMaxWidth: 120,
      columnMinWidth: 120,
      renderer: (result) => (
        <TableTextWithEllipsis fontWeight={600} value={`Result-${result.test_result_num}`} />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
      columnMaxWidth: 190,
      columnMinWidth: 190,
      renderer: (result) => {
        return <StatusBadge status={result.status.toUpperCase() as any} />;
      },
    },
    {
      field: "value",
      headerName: "Result",
      visible: true,
      columnMinWidth: 150,
      columnMaxWidth: 150,
      renderer: (result) => <TableTextWithEllipsis value={result.value} />,
    },
    {
      field: "parameter_values",
      headerName: "Parameter Values",
      visible: true,
      columnMinWidth: 300,
      columnMaxWidth: 350,
      renderer: (result) => (
        <ParametersList
          maxCount={2}
          parameters={
            result?.parameter_values?.map((parameter_value) => ({
              id: parameter_value.id,
              name: parameter_value?.parameter?.name,
              type: parameter_value?.field_type,
              parameterId: parameter_value?.parameter?.id,
              parameterValue: parameter_value?.value,
              parameterValueName: parameter_value?.value_name,
            })) ?? []
          }
          fetchUserDetails={useUsers}
          fetchAssetDetails={useAsset}
        />
      ),
    },
    {
      field: "assignee_name",
      headerName: "Assignee",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
      renderer: (result) => <UserTag name={result.assignee_name ? result.assignee_name : "-"} />,
    },
    {
      field: "updated_on",
      headerName: "Tested On",
      visible: true,
      columnMinWidth: 110,
      columnMaxWidth: 110,
      renderer: (result) => defaultDateDisplay(result?.updated_on),
    },
    {
      field: "updated_by",
      headerName: "Tested By",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
      renderer: (result) => <UserTag name={result.created_by.name} />,
    },
  ];

  return (
    <>
      <APITable
        title="Metric Results"
        useGetData={useMetricResults}
        queryParams={{ "parameter_value[]": model }}
        columnsVisibility={metricResultColumnsVisibility}
        smallEmptyHeight={true}
        emptyTableComponent={
          <EmptyTable
            variant="metrics-empty"
            label="Metric Results"
            description={`Add a result to see the data here.`}
          />
        }
        tableSize="small"
      />
      <APITable
        useGetData={useTestResults}
        queryParams={{ "parameter_value[]": model }}
        columnsVisibility={testResultColumnsVisibility}
        smallEmptyHeight={true}
        emptyTableComponent={
          <EmptyTable
            variant="test"
            strokeWidth="2px"
            label="Test Results"
            description="Create a test run and then add result to see data here."
            actionLabel=""
            action={() => {}}
          />
        }
        title="Test Results"
        tableSize="small"
      />
    </>
  );
};

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavbarItem } from "../atoms/filter/NavbarItem";
import NavbarArrow from "../atoms/navbar/Arrow";
import CloseNavbarButton from "../atoms/navbar/Close";
import { INavbarIconVariant } from "../atoms/navbar/Icon";
import { useSubscription } from "../context/SubscriptionContext";
import { useAppThemeContext } from "../context/ThemeContext";
import { useAuth } from "../hooks/useAuth";
import ExpandableBox from "../molecules/ExpandableBox";
import MobileNavbar from "../molecules/MobileNavbar";
import { getPlanHierarchy } from "../utilities/PlanHierarchy";
import { ScreenSmallerThen } from "../utilities/UIHelper";
import config from "../config/config";

const ColumnOrientedBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }} gap="5px">
      {children}
    </Box>
  );
};

const CenteredBox = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>{children}</Box>;
};

interface IMenuItemProps {
  variant: INavbarIconVariant;
  label: string;
  route: string;
  isEnterpriseFeature: boolean;
  subMenuItems?: {
    label: string;
    route: string;
  }[];
}

const auditorToolKitItems: IMenuItemProps[] = [
  {
    variant: "evidence-tests",
    label: "Audits",
    route: "/audits",
    isEnterpriseFeature: true,
    subMenuItems: [
      {
        label: "Audits",
        route: "/audits",
      },
      {
        label: "Reports",
        route: "/audits/reports",
      },
    ],
  },
];

const toolKitItems: IMenuItemProps[] = [
  {
    variant: "organization",
    label: "Organization",
    route: "/organization",
    isEnterpriseFeature: false,
    subMenuItems: [
      {
        label: "Overview",
        route: "/organization/overview",
      },
      {
        label: "Users",
        route: "/organization/users",
      },
      {
        label: "Roles",
        route: "/organization/roles",
      },
      {
        label: "IAM Policies",
        route: "/organization/policies",
      },
      {
        label: "Integrations",
        route: "/organization/integrations",
      },
    ],
  },
  {
    variant: "compliance",
    label: "Oversight",
    route: "/oversight",
    isEnterpriseFeature: false,
    subMenuItems: [
      {
        label: "Use Cases",
        route: "/oversight/use-cases",
      },
      {
        label: "Vendors",
        route: "/oversight/vendors",
      },
      {
        label: "Assessments",
        route: "/oversight/impact-assessment",
      },
      {
        label: "Frameworks",
        route: "/oversight/frameworks",
      },
      {
        label: "Library",
        route: "/oversight/library",
      },
      {
        label: "Risk Scenarios",
        route: "/oversight/risk-scenarios",
      },
      {
        label: "Evidence Tests",
        route: "/oversight/evidence-tests",
      },
    ],
  },
  {
    variant: "asset",
    label: "Assets",
    route: "/assets",
    isEnterpriseFeature: true,
    subMenuItems: [
      {
        label: "Policies",
        route: "/assets/policies",
      },
      {
        label: "Documentation",
        route: "/assets/documentation",
      },
      {
        label: "Models",
        route: "/assets/models",
      },
      {
        label: "Datasets",
        route: "/assets/datasets",
      },
      {
        label: "Ext. Resources",
        route: "/assets/external-resources",
      },
    ],
  },
  {
    variant: "workflow",
    label: "Workflows",
    route: "/workflows",
    isEnterpriseFeature: true,
  },
  {
    variant: "test-sm",
    label: "Test Suite",
    route: "/tests",
    isEnterpriseFeature: true,
    subMenuItems: [
      {
        label: "Test Runs",
        route: "/tests/test-runs",
      },
      {
        label: "Test Cases",
        route: "/tests/test-cases",
      },
      {
        label: "Metrics",
        route: "/tests/metrics",
      },
    ],
  },
  {
    variant: "evidence-tests",
    label: "Audits",
    route: "/audits",
    isEnterpriseFeature: true,
    subMenuItems: [
      {
        label: "Audits",
        route: "/audits",
      },
      {
        label: "Reports",
        route: "/audits/reports",
      },
    ],
  },
];

interface INavbarProps {
  userDisplayName: string;
  organizationName: string;
  hasOrganization: boolean;
  handleTasksModal: () => void;
  userHasActiveTasks?: boolean;
  showFeaturePaywall: (route: string) => void;
}

const Navbar = ({ showFeaturePaywall }: INavbarProps) => {
  const [menuMobileOpen, setMenuMobileOpen] = useState(false);
  const navigate = useNavigate();
  const { navbarExpanded, setNavbarExpanded } = useAppThemeContext();
  const isSmallScreen = ScreenSmallerThen();
  const { pathname } = useLocation();
  const { isAuditor } = useAuth();

  useEffect(() => {
    if (menuMobileOpen) setNavbarExpanded(true);
  }, [menuMobileOpen]);

  const handleNavigate = (route: string) => {
    navigate(route);
    setMenuMobileOpen(false);
  };
  const isSettingsPage = pathname.includes("/organization/settings");
  const { currentPlan } = useSubscription();
  const isEnterprise = currentPlan?.name === "Fairo Enterprise";

  const menuItems = isAuditor() ? auditorToolKitItems : toolKitItems;
  return (
    <>
      {((isSmallScreen && menuMobileOpen) || !isSmallScreen) && (
        <ExpandableBox
          expanded={navbarExpanded}
          onExpand={() => setNavbarExpanded(!navbarExpanded)}
        >
          <>
            <ColumnOrientedBox>
              {menuItems.map((item) => (
                <NavbarItem
                  expanded={navbarExpanded}
                  label={item.label}
                  variant={item.variant}
                  selected={pathname.includes(item.route)}
                  subMenuItems={item.subMenuItems}
                  onClick={() => {
                    if (
                      getPlanHierarchy(currentPlan?.name ?? "") >=
                        getPlanHierarchy(
                          item.isEnterpriseFeature ? "Fairo Enterprise" : "Fairo Premium"
                        ) ||
                      config.appHostname === "localhost"
                    ) {
                      handleNavigate(item.route);
                      return;
                    }
                    showFeaturePaywall(item.route.replace("/", ""));
                  }}
                  labelVisible={navbarExpanded}
                  fullWidth={true}
                />
              ))}
              {isSmallScreen && (
                <CenteredBox>
                  <CloseNavbarButton onClick={() => setMenuMobileOpen(!menuMobileOpen)} />
                </CenteredBox>
              )}
            </ColumnOrientedBox>
            {!isSmallScreen && (
              <Box display="flex" width="100%" justifyContent="center">
                <NavbarArrow
                  direction={navbarExpanded ? "left" : "right"}
                  onClick={() => setNavbarExpanded(!navbarExpanded)}
                />
              </Box>
            )}
          </>
        </ExpandableBox>
      )}
      {isSmallScreen && !menuMobileOpen && (
        <MobileNavbar expanded={true} onExpand={() => setMenuMobileOpen(!menuMobileOpen)} />
      )}
    </>
  );
};

export default Navbar;

import { Box, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { CardTableItemLoader } from "./CardTableLoader";
import { CardTable } from "../CardTable";
import SecondaryBox from "../../atoms/box/SecondaryBox";

export const FrameworksListLoader = () => {
  const theme = useTheme();
  return (
    <Box paddingBottom="80px">
      <SecondaryBox gap="10px">
        <CardTable
          renderItem={(item) => <></>}
          hasPaddingTop={false}
          hasPaddingBottom={false}
          hasBorderRadiusTop={false}
          params={{}}
          useGetData={() => ({
            data: { results: [], previous: null, next: null, count: 0 },
            isLoading: true,
          })}
          rowsPerPage={4}
          padding={"0px"}
          showBorder={false}
          rowsMenu={[4, 8, 12]}
        />
      </SecondaryBox>
    </Box>
  );
};
